import Button, { ButtonType } from "components/Button";
import PlayerCloseFullscreen from "components/Icons/PlayerCloseFullscreen";
import PlayerOpenFullscreen from "components/Icons/PlayerOpenFullscreen";
import SkipAd from "components/Icons/SkipAd";
import PlayerSubtitleControler from "components/PlayerSubtitleControler";
import PlayerVolumeControler from "components/PlayerVolumeControler";
import ProgressBar from "components/ProgressBar";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { CSSTransition } from "react-transition-group";

import style from "./style.module.css";

export default class PlayerOverlayAds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubtitleController: false,
      adProgressTime: 0,
      currentPosition: 0,
    };
    this.progressInterval = null;
    this.adLinkRef = React.createRef();
  }

  componentDidMount() {
    this.progressInterval = setInterval(async () => {
      const { playerInstance, adEndPosition } = this.props;
      const playerTimeInfo = await playerInstance?.getTimeInfo();
      const currentPosition = playerTimeInfo?.position || 0;
      let adProgressTime = adEndPosition - currentPosition;
      adProgressTime = adProgressTime < 0 ? 0 : adProgressTime;
      this.setState({
        adProgressTime,
        currentPosition,
      });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.progressInterval);
  }

  hideAllController = () => {
    if (this.props.isAdditionalContentVisible) {
      this.props.handleCloseRemote();
    }
    this.setState({
      showSubtitleController: false,
    });
  };

  setShowSubtitleController = (value) => {
    this.props.handleCloseRemote();
    this.setState({ showSubtitleController: value });
  };

  handleClickOverlay(e) {
    if (this.adLinkRef.current?.contains(e.target)) return;
    e.stopPropagation();
    e.preventDefault();
    if (
      !this.state.showSubtitleController &&
      !this.props.isAdditionalContentVisible
    ) {
      return this.props.setIsOverlayVisibleToFalse();
    } else if (this.state.showSubtitleController) {
      return this.setState({
        showSubtitleController: false,
      });
    } else if (this.props.isAdditionalContentVisible) {
      return this.props.handleCloseRemote();
    }

    if (this.props.config.canPause === false) {
      return;
    }
  }

  handleClickOnFullscreen(e) {
    e.stopPropagation();
    if (this.props.isAdditionalContentVisible) this.props.onClickRemote();
    if (this.state.showSubtitleController)
      this.setShowSubtitleController(false);
    this.props.onClickFullscreen();
  }

  formatProgressTime(ms) {
    if (!ms) {
      return "";
    }

    const time = ms / 1000;
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor(time / 60) % 60;
    const hours = Math.floor(time / 60 / 60);

    let result = `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
    if (hours > 0) {
      result = hours + ":" + result;
    }
    return result;
  }

  sendUserClick = () => {
    const { session } = this.props;
    session?.adUserInteraction("click");
  };

  render() {
    const {
      isVisible,
      isFullscreen,
      info,
      onChangeVolume,
      viewport,
      onChangeTrack,
      menuOpen,
      onSkipAdClick,
      t,
      showSkipAdButton,
      adStartPosition,
      adDuration,
      adUrl,
      adNumber,
      adsTotal,
    } = this.props;

    const { showSubtitleController, adProgressTime, currentPosition } =
      this.state;

    return (
      <CSSTransition
        in={isVisible}
        timeout={500}
        classNames={{
          enter: style.overlayEnter,
          enterActive: style.overlayEnterActive,
          enterDone: style.overlayEnterDone,
          exit: style.overlayExit,
          exitActive: style.overlayExitActive,
          exitDone: style.overlayExitDone,
        }}
      >
        <Fragment>
          <div
            className={style.rootContainer}
            onClick={this.handleClickOverlay.bind(this)}
          >
            {!!adUrl && (
              <div className={style.headerContainer} ref={this.adLinkRef}>
                <div className={style.headerTexts}>
                  <Button
                    type={ButtonType.LINK}
                    rootClassName={style.adLink}
                    href={adUrl}
                    target="_blank"
                    onClick={this.sendUserClick.bind(this)}
                  >
                    <Trans t={t}>Visit advertiser</Trans>
                  </Button>
                </div>
              </div>
            )}
            {!!adNumber && !!adsTotal && (
              <p className={style.adNumber}>
                {t("Sponsored")} • {adNumber} {t("of")} {adsTotal}
              </p>
            )}
            {showSkipAdButton && (
              <Button
                rootClassName={style.skipAdButton}
                type={ButtonType.NORMAL}
                onClick={onSkipAdClick}
              >
                <>
                  {t("Skip Ad")} <SkipAd className={style.skipAdIcon} />
                </>
              </Button>
            )}
            <Fragment>
              <p className={style.progressTime}>
                {this.formatProgressTime(adProgressTime)}
              </p>
            </Fragment>
            <ProgressBar
              rootClassName={style.progressBar}
              position={
                ((currentPosition - adStartPosition) / adDuration) * 100
              }
              draggable={false}
            />
            <div className={style.bottomContainer}>
              <PlayerVolumeControler
                setVolume={onChangeVolume.bind(this)}
                currentVolume={info.volume}
                setShowSubtitleController={this.setShowSubtitleController.bind(
                  this
                )}
                viewport={viewport}
              />
              {(info?.tracks?.text?.length > 0 ||
                info?.tracks?.audio?.length > 0) && (
                <PlayerSubtitleControler
                  setShowSubtitleController={this.setShowSubtitleController.bind(
                    this
                  )}
                  showSubtitleController={showSubtitleController}
                  hide={isVisible}
                  tracks={info.tracks}
                  handleTrackChange={onChangeTrack.bind(this)}
                  menuOpen={menuOpen}
                />
              )}
              {isFullscreen && (
                <PlayerCloseFullscreen
                  className={style.bottomIcon}
                  onClick={this.handleClickOnFullscreen.bind(this)}
                />
              )}
              {!isFullscreen && (
                <PlayerOpenFullscreen
                  className={style.bottomIcon}
                  onClick={this.handleClickOnFullscreen.bind(this)}
                />
              )}
            </div>
          </div>
        </Fragment>
      </CSSTransition>
    );
  }
}
